// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfconnectwithmetamaskwallet from "../../blocks/cfconnectwithmetamaskwallet/src/Cfconnectwithmetamaskwallet";
import Productdescription3 from "../../blocks/productdescription3/src/Productdescription3";
import Filecompression from "../../blocks/filecompression/src/Filecompression";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfinappwallet4 from "../../blocks/cfinappwallet4/src/Cfinappwallet4";
import Freecredits from "../../blocks/freecredits/src/Freecredits";
import Imagethumbnail from "../../blocks/imagethumbnail/src/Imagethumbnail";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";



const routeMap = {
Cfconnectwithmetamaskwallet:{
 component:Cfconnectwithmetamaskwallet,
path:"/Cfconnectwithmetamaskwallet"},
Productdescription3:{
 component:Productdescription3,
path:"/Productdescription3"},
Filecompression:{
 component:Filecompression,
path:"/Filecompression"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfinappwallet4:{
 component:Cfinappwallet4,
path:"/Cfinappwallet4"},
Freecredits:{
 component:Freecredits,
path:"/Freecredits"},
Imagethumbnail:{
 component:Imagethumbnail,
path:"/Imagethumbnail"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},

  Home: {
component:OrderManagement,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
